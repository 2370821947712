import { defineStore } from 'pinia'
import DateTimeHelper from '../helper/DatetimeHelper'

export const useMember = defineStore('member', {
  state: () => ({
    member: {
      id: 0,
      name: '',
      dob: '',
      gender: '',
      email: '',
      phone: '',
      kyc: '',
      file: null,
      qr_code_image: '',
      note: '',
      expired_at: '',
    },
    saved: false,
  }),
  actions: {
    async getItem(id, clubId, type) {
      if (!id) {
        return
      }

      const response = await axios.get(`/api/member/${id}/${clubId}/${type}`)

      if (response.status === 200) {
        this.member = response.data.data
        this.member.type = type

        if (this.member.expired_at) {
          this.member.expired_at = DateTimeHelper.format(this.member.expired_at, 'YYYY-MM-DD')
        }

        if (this.member.related_to_school) {
          this.member.related_to_school = this.member.related_to_school ? true : false
        }
      }
    },
    async save(clubId) {
      this.saved = false

      if (!this.member.id) {
        return
      }

      const response = await axios.put(`/api/member/${this.member.id}`, {
        club_id: clubId,
        ...this.member,
        expired_at: this.member.expired_at ? DateTimeHelper.format(this.member.expired_at, 'YYYY-MM-DD HH:mm:ss') : null,
        related_to_school: this.member.related_to_school ? 1 : 0,
      })

      if (response.status === 200) {
        this.member = response.data.data
      }
    },
    async validatePhone(phone){
      if (!phone) {
        return
      }
      const response = await axios.get(`/api/front-end/validate-phone?phone=${phone}&id=${this.member.id}`)
      return (response.status === 200 && response.data.exist == true) ? true : false;
    }
  }
})

<script setup>
import { ref, onMounted } from 'vue'
import CalendarRange from './CalendarRange.vue';
import ModalSchedule from './ModalSchedule.vue';
import { ChevronLeftIcon } from '@heroicons/vue/24/outline';
import CalendarHelper from '../../../js/helper/CalendarHelper';
import CalendarList from './CalendarList.vue';
import CalendarMonth from './CalendarMonth.vue';

import { useCalendarFactility } from '../../../js/store/calendar-factility';
const facilityStore = useCalendarFactility();

import { useRoom } from '../../../js/store/room';
const roomStore = useRoom();

import { useHoliday } from '../../../js/store/holiday';
const holidayStore = useHoliday();

import { useResource } from '../../../js/store/resource';
const resourcTimeStore = useResource();

const currentYear = ref(CalendarHelper.getCurrentDate().year);
const startMonth = ref(CalendarHelper.getCurrentDate().month);
const currentDay = ref(CalendarHelper.getCurrentDate().day);
const range = ref(3);
//const modes = ref(['範囲', '月', 'リスト']);
const modes = ref(['範囲']);
const mode = ref('範囲');

const modalSchedule = ref(null);
const printFrame = ref(null);
const printContent = ref(null);
const currentURL = ref(window.location.href);

onMounted(() => {
  if(startMonth.value < 4 || startMonth.value > 12) {
    facilityStore.year = currentYear.value - 1;
    holidayStore.year = currentYear.value - 1;
  }
  if(startMonth.value > 3 && startMonth < 13) {
    currentYear.value = currentYear.value - 1
  }
  facilityStore.getItems();
  roomStore.getItems();
  holidayStore.getItems();
  resourcTimeStore.getTimeItems();
})

const setCurrentValue = (m) => {
  startMonth.value = m > 12 ? m - 12 : m;
 if(startMonth.value < 4 || startMonth.value > 12) {
    currentYear.value = facilityStore.year + 1
  } else {
    currentYear.value = facilityStore.year;
  }
}
const showModalSchedule = () => {
  modalSchedule.value.open();
}

const goToday = () => {
  currentYear.value = CalendarHelper.getCurrentDate().year;
  startMonth.value = CalendarHelper.getCurrentDate().month;
  currentDay.value = CalendarHelper.getCurrentDate().day;
}

const goToDate = (n) => {
  if (mode.value === '月') {
    const ym = CalendarHelper.addMonth(currentYear.value, startMonth.value, n);

    currentYear.value = ym.year;
    startMonth.value = ym.month;

    return
  }

  if (mode.value === '日') {
    const ymd = CalendarHelper.addDay(currentYear.value, startMonth.value, currentDay.value, n);

    currentYear.value = ymd.year;
    startMonth.value = ymd.month;
    currentDay.value = ymd.day;

    return
  }

  const ymd = CalendarHelper.addWeek(currentYear.value, startMonth.value, currentDay.value, n);

  currentYear.value = ymd.year;
  startMonth.value = ymd.month;
  currentDay.value = ymd.day;
}

const gotoYear = () => {
  if(startMonth.value < 3 || startMonth.value > 12) {
    currentYear.value = facilityStore.year + 1
  } else {
    currentYear.value = facilityStore.year;
  }
  holidayStore.year = facilityStore.year;
  facilityStore.getItems();
  roomStore.getItems();
  holidayStore.getItems();
  resourcTimeStore.getTimeItems();
}
const printPDF = () => {
  let printContents = printContent.value.innerHTML;
  const printWindow = printFrame.value.contentWindow || printFrame.value.contentDocument;
  printWindow.document.body.innerHTML = printContents
  printWindow.focus();
  printWindow.print();
}

</script>
<template>
    <div class="flex flex-col gap-2 p-4 w-full">
  <div class="flex flex-col lg:flex-row lg:items-center lg:justify-end font-semibold w-full gap-2">
    <button  @click="printPDF" class="px-4 py-1 border border-gray-500 text-slate-700 rounded bg-gray-100 hover:opacity-80 transition delay-75 duration-300 ease-in-out">
      PDF出力
    </button>
    <label class="px-4 py-1 rounded text-white bg-blue-500 border border-blue-500 flex items-center gap-1 hover:opacity-80 transition delay-75 duration-300 ease-in-out justify-center cursor-pointer">
      <svg class="w-4 h-4 fill-white" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 1C20 0.734784 19.8946 0.48043 19.7071 0.292893C19.5196 0.105357 19.2652 0 19 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V17C0 17.2652 0.105357 17.5196 0.292893 17.7071C0.48043 17.8946 0.734784 18 1 18H19C19.2652 18 19.5196 17.8946 19.7071 17.7071C19.8946 17.5196 20 17.2652 20 17V1ZM2 12H5.416C5.80509 12.8921 6.44606 13.6514 7.26031 14.1846C8.07456 14.7178 9.0267 15.0018 10 15.0018C10.9733 15.0018 11.9254 14.7178 12.7397 14.1846C13.5539 13.6514 14.1949 12.8921 14.584 12H18V16H2V12ZM2 2H18V10H13C13 10.7956 12.6839 11.5587 12.1213 12.1213C11.5587 12.6839 10.7956 13 10 13C9.20435 13 8.44129 12.6839 7.87868 12.1213C7.31607 11.5587 7 10.7956 7 10H2V2ZM14 6H11V3H9V6H6L10 10.5L14 6Z" />
      </svg>
      インポート
      <input type="file" class="hidden" accept=".csv,.xls,.xlsx" />
    </label>
    <button class="px-4 py-1 text-white rounded bg-error border border-error hover:opacity-80 transition delay-75 duration-300 ease-in-out">
      サンプルファイル
    </button>
  </div>
  <div class="flex flex-col gap-2 lg:flex-row lg:items-center lg:justify-between">
    <div class="flex flex-col lg:flex-row lg:items-center gap-2 lg:gap-4">
      <div class="font-semibold text-lg">
        <span v-if="mode === '範囲'">{{currentYear}}</span>
        <span v-else-if="mode === '月'">
          {{currentYear}}年{{ startMonth }}月
        </span>
      </div>
      <template v-if="mode === '範囲' || mode === 'リスト'">
        <div class="flex items-center">
          <button 
            v-for="i in 12" 
            class="px-2 py-1 transition delay-75 duration-300 ease-in-out" 
            :class="{
              'bg-gray-200': (startMonth > 3 && i + 3 >= startMonth && i + 3 < startMonth + range) || (i - 9 >= startMonth && i - 9 < startMonth + range)
            }"
            @click.prevent="setCurrentValue(i + 3)"
          >
            {{ i + 3 < 13 ? i + 3 : i - 9 }}
          </button>
        </div>
        <div>
          <select class="border border-gray-300 rounded px-2 py-0.5" v-model="range">
            <option v-for="i in 12" :value="i">{{ i }}ヶ月</option>
          </select>
        </div>
      </template>
      <template v-else>
        <div class="flex rounded divide-x border bg-gray-100">
          <button class="px-2 py-1" @click.prevent="goToDate(-1)">
            <ChevronLeftIcon class="w-5 h-5" />
          </button>
          <button class="px-2 py-1" @click.prevent="goToDate(1)">
            <ChevronLeftIcon class="w-5 h-5 rotate-180" />
          </button>
        </div>
        <button class="border bg-gray-100 rounded px-2 py-1" @click.prevent="goToday">
          今日
        </button>
      </template>
    </div>
    <div class="flex flex-col lg:flex-row lg:items-center gap-2 lg:gap-4">
      <select class="border border-gray-300 rounded px-2 py-0.5 w-fit" v-model="facilityStore.year" @change="gotoYear()">
        <option v-for="i in 7" :value="(new Date().getFullYear() - i) + 6">{{(new Date().getFullYear() - i) + 6}}年度</option>
      </select>
      <div class="flex items-center border border-gray-200 rounded overflow-hidden divide-x divide-gray-200 transition delay-75 duration-300 ease-in-out w-fit">
        <button 
          v-for="m in modes" 
          class="px-2 py-1 hover:bg-gray-100/80 transition delay-75 duration-300 ease-in-out" 
          @click.prevent="mode = m"
          :class="{
            'bg-gray-100/50': mode === m,
            'bg-gray-200': mode !== m
          }"
        >{{ m }}</button>
      </div>
    </div>
  </div>
  <div class="grow lg:overflow-hidden" ref="printContent" id="print-content">
    <div class="overflow-auto scroll-smooth h-full transition-all duration-500 ease-in-out delay-75 max-w-[calc(100vw-60px)] lg:max-w-[calc(100vw-260px)] print:max-w-full">
      <CalendarRange v-if="mode === '範囲'"
        v-model:year="currentYear"
        v-model:range="range"
        v-model:monthStart="startMonth" 
        @edit="showModalSchedule"
      />
      <CalendarMonth v-else-if="mode === '月'" 
        v-model:year="currentYear"
        v-model:monthStart="startMonth" 
        @edit="showModalSchedule"
      />
      <CalendarList v-else-if="mode === 'リスト'" 
        v-model:year="currentYear"
        v-model:range="range"
        v-model:monthStart="startMonth" 
        @edit="showModalSchedule"
        @move="moveSchedule"
      />
    </div>
  </div>
</div>
<ModalSchedule ref="modalSchedule" />
<iframe style="display: none;" :src="currentURL" ref="printFrame"></iframe>
</template>

<script setup>
import { ref, getCurrentInstance } from 'vue'
import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle, Switch } from '@headlessui/vue'
import { CalendarIcon, PhoneIcon, EnvelopeIcon, XMarkIcon, ArrowUpTrayIcon } from '@heroicons/vue/24/outline';
import { useClubStore } from '../../../../js/store/club';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { useMember } from '../../../../js/store/member';

const appUrl = window.location.origin

const $root = getCurrentInstance()?.appContext.config.globalProperties
const clubStore = useClubStore()
const memberStore = useMember()

import QrItemModal from 'views/components/QrItemModal.vue';
const refQrModal = ref(null)

const props = defineProps({
  id: { type: Number, required: true }
})

const isSaved = ref(false)
const isOpen = ref(false)
const open = () => {
  isSaved.value = false
  isOpen.value = true
}
const close = () => {
  isSaved.value = false
  isOpen.value = false
}

const error = ref({})
const save = async () => {
  error.value = {}
  if (!clubStore.item || !clubStore.item.id) {
    $root.push.error('クラブが選択されていません')
    return
  }

  memberStore.saved = true

  if (!memberStore.member.name) {
    $root.push.error('名前を入力してください')
    return
  }

  if (!memberStore.member.phone && memberStore.member.type != 'guest_related') {
    $root.push.error('電話番号を入力してください')
    return
  }
  //validate phone exist
  if (memberStore.member.phone) {
    $root.overlay.loading = true
    const result = await memberStore.validatePhone(memberStore.member.phone)
    if (result) {
      $root.overlay.loading = false
      error.value.phone_exist = true
      $root.push.error('この電話番号がすでに登録されました。')
      return
    }
  }

  $root.overlay.loading = true
  try {
    await memberStore.save(clubStore.item.id)
    $root.push.success('メンバーを保存しました')

    // update member list
    clubStore.getMembers()

    close()
  } catch (error) {
    $root.push.error('メンバーの保存に失敗しました')
  }
  $root.overlay.loading = false
}

const changeKycHandler = (e) => {
  const file = e.target.files[0]
  if (!file) {
    return
  }

  const reader = new FileReader()
  reader.onload = (e) => memberStore.member.kyc = e.target.result
  reader.readAsDataURL(file)
}

defineExpose({ open, close })
</script>
<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-10">
      <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100"
        leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95">
            <DialogPanel
              class="w-full max-w-xl transform bg-white text-left align-middle shadow-xl transition-all border-t-2 border-t-primary">
              <!-- add member to club #A -->
              <DialogTitle as="h3"
                class="text-lg font-medium leading-6 text-gray-900 flex items-center gap-2 py-2 px-4 bg-graysp justify-between">
                {{ memberStore.member.id > 0 ? 'メンバー詳細' : 'メンバー追加'}}
                <div>
                  <button title="閉じる" class="p-1 transition duration-300 delay-75 ease-in-out" @click="close">
                    <XMarkIcon class="w-6 h-6 text-gray-400" @click="close" />
                  </button>
                </div>
              </DialogTitle>
              <div class="flex flex-col gap-2">
                <div class="flex flex-col gap-2 px-4 py-2">
                  <!-- name -->
                  <div class="flex flex-col">
                    <label class="flex items-center gap-1">
                      名前
                      <span class="text-error">*</span>
                    </label>
                    <input type="text" class="px-2 py-1 border border-gray-200 rounded" :class="{
                      'bg-red/10 border-red': isSaved && !memberStore.member.name
                    }" v-model="memberStore.member.name" />
                  </div>

                  <div class="flex items-center gap-2">
                    <!-- phone -->
                    <div class="flex flex-col w-1/2">
                      <label class="flex items-center gap-1">
                        電話番号
                        <span class="text-error" v-if="memberStore.member.type != 'guest_related'">*</span>
                      </label>
                      <div class="relative min-w-48">
                        <PhoneIcon class="w-6 h-6 text-gray-400 absolute pt-2 pl-2" />
                        <input type="text" class="px-2 py-1 border border-gray-200 rounded w-full pl-8" :class="{
                          'bg-red/10 border-red': isSaved && !memberStore.member.phone
                        }" v-model="memberStore.member.phone" placeholder="xxx-xxxx-xxxx" />
                      </div>
                    </div>

                    <!-- email -->
                    <div class="flex flex-col w-1/2">
                      <label>メールアドレス</label>
                      <div class="relative min-w-64">
                        <EnvelopeIcon class="w-6 h-6 text-gray-400 absolute pt-2 pl-2" />
                        <input type="email" class="px-2 py-1 border border-gray-200 rounded w-full pl-8"
                          v-model="memberStore.member.email" placeholder="name@example.com" />
                      </div>
                    </div>
                  </div>
                  <div class="flex items-end gap-2">
                    <div class=" text-sm text-error" v-if="error.phone_exist">この電話番号がすでに登録されました。</div>
                  </div>

                  <div class="flex items-end gap-2">
                    <!-- gender -->
                    <div class="flex gap-2 items-center w-1/2">
                      <div class="grow">
                        <div class="flex flex-col">
                          <label>性別</label>
                          <div>
                            <select class="px-2 py-1 border border-gray-200 rounded w-full"
                              v-model="memberStore.member.gender">
                              <option value="male">男性</option>
                              <option value="female">女性</option>
                              <option value="other">その他</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <!-- dob -->
                      <div class="flex-none w-fit">
                        <div class="flex flex-col w-fit">
                          <label>生年月日</label>
                          <div class="max-w-40">
                            <VueDatePicker v-model="memberStore.member.dob" auto-apply format="yyyy-MM-dd"
                              :enable-time-picker="false" locale="ja"
                              input-class-name="px-2 py-1 border border-gray-200 rounded">
                              <template #input-icon>
                                <CalendarIcon class="ml-2 w-4 h-4" />
                              </template>
                              <template
                                #dp-input="{ value, onInput, onEnter, onTab, onClear, onBlur, onKeypress, onPaste, isMenuOpen }">
                                <input type="text" :value="value"
                                  class="pl-8 pr-2 py-1 border border-gray-200 rounded max-w-40"
                                  placeholder="yyyy-mm-dd" />
                              </template>
                            </VueDatePicker>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="items-center w-1/2">
                      <!-- 
                        クラブ一覧　のメンバー数の所、全メンバー数の隣に、学校関係者、子供の人数を表示 
                      -->
                      <!-- <label class="flex items-center gap-1 mb-1.5">
                        <input 
                          type="checkbox" 
                          class="w-4 h-4" 
                          v-model="memberStore.member.related_to_school"
                        />
                        学校関連
                      </label> -->
                      <div class="flex items-center gap-2 mb-1">
                        <Switch v-model="memberStore.member.status" class="relative inline-flex h-6 w-11 items-center rounded-full" :class="{
                          'bg-primary': memberStore.member.status,
                          'bg-gray-200': !memberStore.member.status
                        }">
                          <span class="inline-block h-4 w-4 transform rounded-full bg-white transition" :class="{
                            'translate-x-6': memberStore.member.status,
                            'translate-x-1': !memberStore.member.status
                          }" />
                        </Switch>
                        <span>QR有効・無効</span>
                      </div>

                    </div>
                  </div>

                  <!-- note -->
                  <div class="flex flex-col">
                    <label>備考</label>
                    <textarea class="px-2 py-1 border border-gray-200 rounded" rows="3"
                      v-model="memberStore.member.note"></textarea>
                  </div>

                  <div class="flex items-start gap-2">
                    <div class="w-1/2 flex items-center">
                      <div class="flex flex-col">
                        <label for="expired_at">QRコード有効期限</label>
                        <div class="max-w-40">
                          <VueDatePicker v-model="memberStore.member.expired_at" locale="ja" format="yyyy-MM-dd"
                            :enable-time-picker="false" auto-apply teleport>
                            <template #input-icon>
                              <CalendarIcon class="ml-2 w-4 h-4" />
                            </template>
                            <template #dp-input="{ value }">
                              <input type="text" :value="value"
                                class="border border-gray-200 rounded pl-8 pr-2 py-1 max-w-40"
                                placeholder="yyyy-mm-dd" />
                            </template>
                          </VueDatePicker>
                        </div>
                        <div class="text-xxs text-gray-400 leading-tight">
                          ※QRコードは有効期限が過ぎると使用できなくなります。
                          <div>
                            ※有効期限を設定しない場合は、QRコードは無期限で使用できます。
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="props.id" class="w-1/2 flex flex-col">
                      <div class="flex items-center gap-2">
                        KYC
                      </div>
                      <div class="flex items-center gap-2">
                        <div v-if="memberStore.member.file || memberStore.member.kyc"
                          class="w-8 h-auto group rounded border border-gray-200 flex items-center justify-center overflow-hidden p-px">
                          <img :src="memberStore.member.file ? memberStore.member.file : memberStore.member.kyc"
                            @click.prevent="refQrModal.open(memberStore.member.kyc)" alt="kyc"
                            class="w-full h-auto object-cover" loading="lazy" />
                        </div>
                        <div v-else
                          class="w-8 h-auto group rounded border border-gray-200 flex items-center justify-center overflow-hidden p-px">
                            <img :src="appUrl + '/default.png'" alt="kyc" class="w-full h-auto object-cover rounded" loading="lazy" />
                        </div>

                        <label>
                          <input type="file" class="hidden" accept="image/png, image/jpeg, image/jpg"
                            @change="changeKycHandler" />
                          <div
                            class="px-2 py-0.5 bg-primary text-white rounded hover:opacity-80 transition duration-300 delay-75 ease-in-out flex items-center gap-1 w-fit cursor-pointer">
                            <ArrowUpTrayIcon class="w-4 h-4" />
                            アップロード
                          </div>
                        </label>
                      </div>
                      <div class="text-gray-400 text-xxs leading-tight">
                        <div>※KYCは「本人確認手続き」です。</div>
                        <div>※最大5MBまでのアップロードが可能です。画像のみ対応しています。</div>
                      </div>
                    </div>
                  </div>

                  <div class="flex bg-graysp/50 p-2 rounded">
                    <div class="flex flex-col w-full relative">
                      <div class="absolute -top-1 left-0 font-semibold">QRコード</div>
                      <div class="flex w-full justify-center">
                        <div class="w-32 h-32 bg-graysp border border-gray-200 rounded p-px overflow-hidden">
                          <img :src="memberStore.member.qr_code_image || (appUrl + '/default.png')"
                            class="w-full h-full object-cover rounded" loading="lazy" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="flex items-center justify-between mt-2 w-full bg-gray-300/10 p-4 border-t">
                  <!-- <button v-if="!memberStore.member.qrcode" class="px-4 py-1 bg-sblue text-white rounded hover:opacity-80 transition duration-300 delay-75 ease-in-out">
                    QRコードを再生成
                  </button> -->
                  <div></div>
                  <div class="flex items-center gap-2">
                    <!-- cancel -->
                    <button
                      class="px-4 py-1 bg-white border rounded hover:opacity-80 transition duration-300 delay-75 ease-in-out"
                      @click.prevent="close">
                      キャンセル
                    </button>
                    <!-- add guest -->
                    <button
                      class="px-4 py-1 bg-primary text-white rounded hover:opacity-80 transition duration-300 delay-75 ease-in-out"
                      @click.prevent="save">
                      保存
                    </button>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
  <QrItemModal ref="refQrModal" />
</template>

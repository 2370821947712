<script setup>
import { GlobeAltIcon } from '@heroicons/vue/24/outline'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'

import { useI18n } from 'vue-i18n'
const { t } = useI18n()

import SelectLanguage from './SelectLanguage.vue';
</script>

<template>

  <Popover v-slot="{ open }" class="flex items-center">
    <PopoverButton class="group">
      <GlobeAltIcon class="w-7 h-7 text-white cursor-pointer transition-all delay-75 duration-300 ease-in-out" />
    </PopoverButton>

    <transition enter-active-class="transition duration-200 ease-out" enter-from-class="translate-y-1 opacity-0"
      enter-to-class="translate-y-0 opacity-100" leave-active-class="transition duration-150 ease-in"
      leave-from-class="translate-y-0 opacity-100" leave-to-class="translate-y-1 opacity-0">
      <PopoverPanel class="absolute right-[-55px] top-[53px] z-10  w-screen max-w-fit -translate-x-1/2 transform px-4 sm:px-0 ">
        <div class="overflow-hidden shadow-lg ring-1 ring-black/5">
          <SelectLanguage />
        </div>
      </PopoverPanel>
    </transition>
  </Popover>

</template>
<script setup>
import { ref, getCurrentInstance } from 'vue'
import { TransitionRoot, TransitionChild, Dialog, DialogTitle, DialogPanel } from '@headlessui/vue'
const $root = getCurrentInstance()?.appContext.config.globalProperties
import { useRouter } from 'vue-router'
const router = useRouter();

import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const i18nLocale = useI18n();

import dayjs from 'dayjs'

import { useFronEndClubStore } from 'store/frontend/club';
const fronEndClubStore = useFronEndClubStore()

const emit = defineEmits(['errorPhone'])

const isOpen = ref(false)
const member = ref({})
const open = async (_member) => {
  member.value = _member
  isOpen.value = true
}
const close = () => {
  isOpen.value = false
}
const convertDate = (date) => {
  if (i18nLocale.locale.value == 'vi') {
    return dayjs(date).format('DD/MM/YYYY');
  }
  else if (i18nLocale.locale.value == 'en') {
    return dayjs(date).format('YYYY/MM/DD');
  }
  else {
    return dayjs(date).format('YYYY年MM月DD日');
  }
}

defineExpose({ open, close })

const register = async () => {
  if (fronEndClubStore.member.phone_check == false) {
    $root.overlay.loading = true
    const result = await fronEndClubStore.validatePhone(fronEndClubStore.member.phone)
    if (result) {
      $root.overlay.loading = false
      emit('errorPhone', true)
      close()
      return
    }
  }
  $root.overlay.loading = true
  fronEndClubStore.register(member.value).then((result) => {
    if (result) {
      close()
      if(!fronEndClubStore.info.member){
        fronEndClubStore.filter.phone = fronEndClubStore.member.phone
        localStorage.setItem('frontClubFillter', JSON.stringify(fronEndClubStore.filter))
      }
      router.push({ path: `/front-end/club-membership/${fronEndClubStore.club.alias}/3` })
    }
    else {
      $root.push.error(t('error'))
    }
  }).catch((err) => {
    $root.push.error(t('error'))
    close()
  });
  $root.overlay.loading = false
}
</script>
<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-10">
      <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100"
        leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95">
            <DialogPanel
              class="w-full max-w-xl transform overflow-hidden bg-white text-left align-middle shadow-xl transition-all rounded-lg p-4">
              <div class="mt-2 flex flex-col gap-2">

                <div class="flex flex-row p-2 gap-4">
                  <div class="w-2/5">{{ $t('type') }}</div>
                  <div class="w-3/5">{{ (fronEndClubStore.filter.related) ? $t(`register_type.${member.relationship}`) : $t(`register_type.guest`) }}</div>
                </div>

                <div class="flex flex-row p-2 gap-4">
                  <div class="w-2/5">{{ $t('name') }}</div>
                  <div class="w-3/5">{{ member.name }}</div>
                </div>

                <div class="flex flex-row p-2 gap-4">
                  <div class="w-2/5">{{ $t('romaji_name') }}</div>
                  <div class="w-3/5">{{ member.romaji_name }}</div>
                </div>

                <div class="flex flex-row p-2 gap-4">
                  <div class="w-2/5">{{ $t('phone_number') }}</div>
                  <div class="w-3/5">{{ member.phone }}</div>
                </div>

                <div class="flex flex-row p-2 gap-4" v-if="member.email">
                  <div class="w-2/5">{{ $t('mail_address') }}</div>
                  <div class="w-3/5">{{ member.email }}</div>
                </div>

                <div class="flex flex-row p-2 gap-4">
                  <div class="w-2/5">{{ $t('birthday') }}</div>
                  <div class="w-3/5">{{ convertDate(member.dob) }}</div>
                </div>

                <div class="flex flex-col p-2 justify-center items-center" v-if="member.kyc">
                  <img :src="member.kyc" class="max-h-60 w-auto">
                </div>

                <div class="flex flex-row items-center justify-center gap-4">
                  <button type="button" @click="close"
                    class="w-1/2 border shadow text-gray-800 font-bold  py-1 rounded-md hover:opacity-80">
                    {{ $t('back') }}
                  </button>
                  <button type="submit" @click.once="register"
                    class="w-1/2 shadow bg-sky-500  text-white font-bold py-1 rounded-md hover:opacity-80 ">
                    {{ $t('register') }}
                  </button>
                </div>

              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

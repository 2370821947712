<script setup>
import { ref, onMounted, getCurrentInstance } from 'vue'
import EditTabResource from './resource/EditTabResource.vue';
import EditTabMember from './member/EditTabMember.vue';
import EditTabSchedule from './schedule/EditTabSchedule.vue';

const tab = ref('member')
</script>
<template>
  <div class="flex flex-col w-full gap-4">
    <div class="flex items-center justify-between border-b-2 border-b-primary bg-graysp">
      <div class="w-1/3 hover:bg-primary/30" :class="{
        'bg-primary text-white': tab === 'member'
      }">
        <button class="py-1 w-full text-center transition-all delay-75 duration-300 ease-in-out" @click.prevent="tab = 'member'">メンバー</button>
      </div>
      <div class="w-1/3 hover:bg-primary/30" :class="{
        'bg-primary text-white': tab === 'schedule'
      }">
        <button class="py-1 w-full text-center transition-all delay-75 duration-300 ease-in-out" @click.prevent="tab = 'schedule'">スケジュール</button>
      </div>
      <div class="w-1/3 hover:bg-primary/30" :class="{
        'bg-primary text-white': tab === 'resource'
      }">
        <button class="py-1 w-full text-center transition-all delay-75 duration-300 ease-in-out" @click.prevent="tab = 'resource'">物品借用設定</button>
      </div>
    </div>
    <EditTabMember v-show="tab === 'member'" />
    <EditTabSchedule v-show="tab === 'schedule'" />
    <EditTabResource v-show="tab === 'resource'" />
  </div>
</template>
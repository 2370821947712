import { defineStore } from 'pinia'
import DateTimeHelper from 'helper/DatetimeHelper';
export const useEntranceStore = defineStore('entrance', {
  state: () => ({
    // list
    headers: [
      { text: '名前', field: 'name', width: '200px' },
      { text: 'タイプ', field: 'type', width: '100px' },
      { text: '電話番号', field: 'phone', width: '200px' },
      { text: '回数', field: 'count', width: '50px' },
      { text: '最後入校日時', field: 'checkin_school', width: '200px' },
      { text: '最後出校日時', field: 'checkout_school', width: '200px' },
      { text: '最後入出校目的', field: 'reason', }
    ],
    arrType: {
      'guest': 'ゲスト',
      'club': 'クラブ',
      'parent': '保護者',
      'student': '生徒',
      'staff': '職員',
      'event': 'イベント',
    },
    filter: { text: '', type: '', group: '', from: '', to: '', page: 1, limit: 20 },
    pagination: { total: 0, currentPage: 1, perPage: 1 },

    items: [],
    item: {},

    //detail
    filterDetail: { from: '', to: '', uid: 0, type: '', page: 1, limit: 20 },
    detailItems: [],
    paginationDetail: { total: 0, currentPage: 1, perPage: 1 },

    // report
    reportFilter: { text: '', type: 'event', group: '', date: [] },
    reportItems: {},
  }),
  actions: {

    async getItems() {
      const response = await axios.post('/api/list-visitor', this.filter)
      //reset value
      this.items = []
      this.pagination = { total: 0, currentPage: 1, perPage: 1 }

      //assign value
      if (response.status == 200 && response.data.total > 0) {
        this.items = response.data.data.map(item => {
          item.checkin_school = DateTimeHelper.format(item.checkin_school, 'YYYY/MM/DD(ddd)　HH:mm')
          item.checkout_school = DateTimeHelper.format(item.checkout_school, 'YYYY/MM/DD(ddd)　HH:mm')
          item.type_name = (item.type == 'staff') ? response.data.positions[item.position] : this.arrType[item.type]
          return item
        })
        this.pagination.total = response.data.total
        this.pagination.currentPage = response.data.current_page
        this.pagination.perPage = response.data.per_page
      }
    },


    async getItemDetail() {      
      const response = await axios.post('/api/visitor-detail', this.filterDetail)
      this.detailItems = []
      this.paginationDetail = { total: 0, currentPage: 1, perPage: 1 }
      if (response.status == 200 && response.data.total > 0) {
        this.detailItems = response.data.data.map(item => {
          item.checkin_school = DateTimeHelper.format(item.checkin_school, 'YYYY/MM/DD(ddd)　HH:mm')
          item.checkout_school = DateTimeHelper.format(item.checkout_school, 'YYYY/MM/DD(ddd)　HH:mm')
          return item
        })
        this.paginationDetail.total = response.data.total
        this.paginationDetail.currentPage = response.data.current_page
        this.paginationDetail.perPage = response.data.per_page
      }
    },

    async fetchReportDatas() {
      const response = await axios.post('/api/list-visitor/report', this.reportFilter)
      let datas = {}, totals = {}
      this.reportItems = { datas: {}, totals: {}, max: 0, min: 0, members: 0, totalCheckin: 0, lostCheckout: 0 },
        response.data.items.map((item) => {
          const hour = DateTimeHelper.formatDatetime(item.checkin_school, 'H')
          const date = DateTimeHelper.formatDatetime(item.checkin_school, 'YYYY-MM-DD')

          if (!datas[date]) {
            datas[date] = {}
          }
          if (!datas[date][hour]) {
            datas[date][hour] = 0
          }
          if (!totals[date]) {
            totals[date] = 0
          }
          datas[date][hour]++

          if (datas[date][hour] > this.reportItems.max) {
            this.reportItems.max = datas[date][hour]
          }
          totals[date]++
        })
      this.reportItems.datas = datas
      this.reportItems.totals = totals
      this.reportItems.members = response.data.members
      this.reportItems.totalCheckin = response.data.totalCheckin
      this.reportItems.lostCheckout = response.data.lostCheckout

    }
  }
})

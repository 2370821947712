<script setup>
import { ref, getCurrentInstance } from 'vue'
import { TransitionRoot, TransitionChild } from '@headlessui/vue'
import { MagnifyingGlassIcon, DocumentDuplicateIcon, XMarkIcon } from '@heroicons/vue/24/outline';
import EditTabmemberAddGuestModal from './EditTabMemberAddGuestModal.vue'
import EditTabmemberEditGuestModal from './EditTabMemberEditGuestModal.vue'
import EditTabMemberAddMemberModal from './EditTabMemberAddMemberModal.vue'
import EditTabMemberEditMemberModal from './EditTabMemberEditMemberModal.vue'
import { useClubStore } from '../../../../js/store/club';
import EditTabMemberTable from './EditTabMemberTable.vue'

const $root = getCurrentInstance()?.appContext.config.globalProperties
const clubStore = useClubStore()

const isAddGuestModalOpen = ref(false)
const isEditGuestModalOpen = ref(false)
const isAddMemberModalOpen = ref(false)
const isEditMemberModalOpen = ref(false)

const copied = ref(false)
const countTime = ref(0)
const copyUrl = () => {
  const urlClub = `${window.location.origin}/front-end/club-membership/${clubStore.item.alias}`
  navigator.clipboard.writeText(urlClub)
  copied.value = true
  countTime.value = 4000
  const interval = setInterval(() => {
    countTime.value -= 100
    if (countTime.value <= 0) {
      clearInterval(interval)
      copied.value = false
    }
  }, 100)
}

const doSearchMembers = async () => {
  clubStore.currentPageMembers = 1

  $root.overlay.loading = true
  await clubStore.getMembers()
  $root.overlay.loading = false
}

const removeMember = async (id) => {
  const c = confirm('本当に削除しますか？')
  if (!c) {
    return
  }

  $root.overlay.loading = true
  try {
    await clubStore.removeMember(id)
    $root.push.success('メンバーを削除しました')
  } catch (error) {
    $root.push.error('メンバーの削除に失敗しました')
  }
  $root.overlay.loading = false
}

const genQrcodes = async () => {
  const c = confirm('QRコードを生成しますか？\r\nまだQRコードを持っていないメンバーのみQRコードを生成します。')
  if (!c) {
    return
  }

  try {
    await clubStore.genQrcodes()
    $root.push.success('QRコードを生成しました')
    setTimeout(() => {
        window.location.reload()
    }, 2000)
  } catch (error) {
    $root.push.error('QRコードの生成に失敗しました')
  }
}

const openAddGuestModal = (id) => {
  clubStore.member.id = id
  isAddGuestModalOpen.value.open()
}

const openEditGuestModal = (id) => {
  clubStore.member.id = id
  isEditGuestModalOpen.value.open()
}

const openAddMemberModal = (id) => {
  isAddMemberModalOpen.value.open()
}

const openEditMemberModal = (id) => {
  clubStore.member.id = id
  isEditMemberModalOpen.value.open()
}

const editItem = (obj) => {
  if (obj.type === 'guest' || obj.type === 'guest_related') {
    openEditGuestModal(obj.id)
    return
  }

  openEditMemberModal(obj.id)
}
const addNewGuest = () => {
  clubStore.member.id = 0
  clubStore.member.phone = clubStore.searchText
  isAddGuestModalOpen.value.open()
}
</script>
<template>
  <div class="flex flex-col items-center justify-center">
    <div class="flex flex-col max-w-2xl gap-2">
      <!-- <div class="flex items-start gap-4 justify-between">
        <div class="flex items-center gap-2 font-semibold">
          <button class="px-4 py-1 bg-blue-500 text-white rounded" @click.prevent="openAddMemberModal(0)">
            メンバー追加
          </button>
          <button class="px-4 py-1 bg-oblue text-white rounded" @click.prevent="openAddGuestModal(0)">
            ゲスト追加
          </button>
        </div>
        <div class="flex flex-col text-gray-500 leading-tight text-xs">
          ※メンバー：JSHに属する学校関係者（生徒や保護者等）
          <span>※ゲスト：JSHに属さない学校関係者以外のメンバー</span>
        </div>
      </div> -->
      <div class="flex items-start gap-2 justify-between">
        <div class="grow">
          <div class="flex items-center gap-2">
            <div class="grow">
              <div class="relative w-full">
                <input type="text" class="w-full px-2 py-1 border border-gray-200 rounded" v-model.trim="clubStore.searchMemberText" placeholder="名前、電話番号で検索" />
                <button class="absolute right-2 top-2 w-4 h-4 text-gray-400 cursor-pointer" v-show="clubStore.searchMemberText" @click="clubStore.searchMemberText = ''" title="クリア">
                  <XMarkIcon class="w-4 h-4" />
                </button>
              </div>
            </div>
            <div class="flex-none w-fit">
              <button class="p-1.5 border rounded border-gray-200" title="検索" @click.prevent="doSearchMembers">
                <MagnifyingGlassIcon class="w-4 h-4" />
              </button>
            </div>
          </div>
        </div>
        <div class="w-fit flex-none">
          <div class="flex items-center gap-2 font-semibold">
            <button class="px-4 py-1 border border-primary text-primary rounded" title="クラブメンバー登録フォームのURLをコピーできる" @click.prevent="copyUrl">
              登録URLコピー
            </button>
            <button class="px-4 py-1 border border-primary text-primary rounded" title="QRコード生成" @click.prevent="genQrcodes">
              QRコード生成
            </button>
          </div>
        </div>
      </div>
      <TransitionRoot :show="copied">
        <TransitionChild
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-y-2"
          enter-to="translate-y-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-y-0"
          leave-to="-translate-y-2"
        >
          <div 
            class="flex flex-col transition-all delay-75 duration-300 ease-in-out"
            :class="{
              'opacity-0': !copied,
            }"
          >
            <div class="flex h-px w-full bg-primary/10 items-center">
              <div 
              class="flex h-full bg-primary transition-all duration-100 ease-in-out" 
              :style="{ width: `${(4000 - countTime) / 4000 * 100}%` }"
              :class="{ 'w-0': countTime <= 0 }" 
              ></div>
            </div>
            <div class="flex items-center gap-2 px-2 py-1 bg-primary/10 rounded text-xs justify-between">
              <div class="flex items-center gap-1">
                <DocumentDuplicateIcon class="w-3 h-3 text-primary" />
                <span class="text-primary font-semibold">URLをコピーしました。共有できます。</span>
              </div>
              <button title="閉じる" @click.prevent="copied = false">
                <XMarkIcon class="w-4 h-4 text-gray-400" />
              </button>
            </div>
          </div>
        </TransitionChild>
      </TransitionRoot>
      <EditTabMemberTable @edit="editItem" @remove="" />
    </div>
    <EditTabmemberAddGuestModal ref="isAddGuestModalOpen" />
    <EditTabmemberEditGuestModal ref="isEditGuestModalOpen" :id="clubStore.member.id" />
    <EditTabMemberAddMemberModal ref="isAddMemberModalOpen" @addNewGuest="addNewGuest"/>
    <EditTabMemberEditMemberModal ref="isEditMemberModalOpen" />
  </div>
</template>
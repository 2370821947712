<script setup>
import { computed } from 'vue'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { PlusCircleIcon, XMarkIcon } from '@heroicons/vue/24/outline';
import { useClubRequestStore } from '../../../../js/store/club-request';
import { useClubStore } from '../../../../js/store/club';

const clubRequestStore = useClubRequestStore()
const clubStore = useClubStore()

const isAdded = (id) => {
  return clubStore.item.resources.some(r => r.resource_id === id)
}

// computed
const isAddedAll = computed(() => {
  return clubRequestStore.resources.length > 0 && 
    clubRequestStore.resources.length === clubStore.item.resources.length
})
</script>
<template>
  <Popover v-slot="{ close }" class="relative flex items-center">
    <PopoverButton class="bg-blue-500 text-white font-semibold px-4 py-1 rounded hover:opacity-80">
      物品追加
    </PopoverButton>

    <transition
      enter-active-class="transition duration-200 ease-out"
      enter-from-class="translate-y-1 opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="transition duration-150 ease-in"
      leave-from-class="translate-y-0 opacity-100"
      leave-to-class="translate-y-1 opacity-0"
    >
      <PopoverPanel
        class="absolute right-full -top-1 z-10 mt-1 w-screen max-w-80 transform"
      >
        <div class="flex flex-col bg-white shadow-lg border-t-2 border-t-primary">
          <div class="flex w-full bg-gray-100 px-2 py-1 items-center justify-between">
            <h4 class="font-semibold text-primary-500 flex items-center gap-1 ">
              物品追加
            </h4>
            <!-- cancel button -->
            <button @click.prevent="close">
              <XMarkIcon class="w-6 h-6 text-error cursor-pointer hover:scale-110" />
            </button>
          </div>
          <div class="p-2">
            <div v-if="clubRequestStore.resources.length" class="flex flex-col gap-1">
              <!-- list resources with text and plus icon, if added just show remove icon -->
              <div v-for="item in clubRequestStore.resources" class="max-h-80 overflow-y-auto">
                <div v-if="!isAdded(item.id)" class="flex items-start gap-1 px-2 py-px rounded bg-gray-100 border hover:shadow group h-full">
                  <div class="flex-none w-fit">
                    <!-- @click.prevent="item.qty > 0 ? clubStore.addResource(item.id) : null" -->
                    <PlusCircleIcon 
                      @click.prevent="clubStore.addResource(item.id)"
                      class="w-5 h-5 hover-group:scale-110" 
                      :class="{
                        'text-primary cursor-pointer': item.quantity > 0,
                        // 'text-gray-300': item.qty < 1
                      }"
                    />
                  </div>
                  <div class="grow text-left truncate">
                    <div :title="item.name">{{ item.name }}</div>
                  </div>
                  <div class="w-fit flex-none text-xs">
                    <!-- <span class="text-green font-semibold" title="在庫あり">{{ item.qty }}</span> -->
                    ／
                    <span class="text-charcoal font-semibold" title="合計">{{ item.quantity }}</span>
                    <span class="text-grey-500">{{ item.unit }}</span>
                  </div>
                </div>
              </div>

              <!-- <div v-if="!isAddedAll" class="flex items-center justify-end gap-4 text-xs mt-4 text-gray-400"> -->
                <!-- avaiable -->
                <!-- <div class="flex items-center gap-1">
                  <span class="w-3 h-3 rounded-sm bg-green"></span>
                  <span>在庫あり</span>
                </div> -->
                <!-- total orginal -->
                <!-- <div class="flex items-center gap-1">
                  <span class="w-3 h-3 rounded-sm bg-charcoal"></span>
                  <span>合計</span>
                </div> -->
              <!-- </div> -->
            </div>
            <div v-else class="flex items-center justify-center h-32">
              <div class="flex flex-col items-center gap-2">
                <span class="text-gray-500">物品がありません</span>
              </div>
            </div>
            <div v-if="isAddedAll" class="flex items-center justify-center h-32">
              <div class="flex flex-col items-center gap-2">
                <span class="text-green">すべての物品を追加しました</span>
              </div>
            </div>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

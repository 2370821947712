import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { useAuthStore } from './auth'

export const useNavigationStore = defineStore('navigation', () => {
  const authStore = useAuthStore()

  const items = computed(() => {
    if (!authStore.isAuthenticated) {
      return []
    }

    const roles = authStore.user.role
    if (!roles) {
      return []
    }

    if (roles.includes('school')) {
      return [
        {
          text: '学校管理',
          key: 'school',
          children: [
            {
              text: 'アカウント管理',
              to: '/account',
              active: false,
            },
            {
              text: 'データインポート',
              to: '/import',
              active: false,
            },
            {
              text: '入出校管理',
              to: '/entrance',
              active: false,
            },
            {
              text: '在籍一覧管理',
              to: '/database',
              active: false,
            },
            {
              text: '職員管理',
              to: '/staff',
              active: false,
            },
            {
              text: 'ゲスト管理',
              to: '/card-guest',
              active: false,
            },
            {
              text: '学校年間行事',
              to: '/event-calendar',
              active: false,
            },
            {
              text: '施設利用表',
              to: '/facility',
              active: false,
            },
            // resources management
            {
              text: '施設利用管理',
              to: '/resources',
              active: false,
            },
            // 承認作業用カレンダー
            {
              text: '承認作業用カレンダー',
              to: '/approval',
              active: false,
            },
          ],
        },
        // visitor management
        {
          text: 'イベント管理',
          key: 'event',
          children: [
            // history check in/out
            {
              text: 'イベントチケット',
              to: '/event-history',
              active: false,
            },
            // event group
            {
              text: 'グループ管理',
              to: '/event-group',
              active: false,
            },
            // email setting
            {
              text: 'メール設定',
              to: '/event/email-config',
              active: false,
            },
            // email template
            {
              text: 'テンプレート設定',
              to: '/event/email-template',
              active: false,
            },
            // approval
            {
              text: '承認作業用カレンダー',
              to: '/event/email-approval-template',
              active: false,
            },
          ]
        },
        {
          text: 'PTA',
          key: 'PTA',
          children: [
            {
              text: 'メール配信',
              to: '/send-email',
              active: false,
            },
            {
              text: '配信履歴',
              to: '/email-history',
              active: false,
            },
            {
              text: 'メール設定',
              to: '/setting/email-config',
              active: false,
            },
            {
              text: 'テンプレート設定',
              to: '/setting/email-template',
              active: false,
            }
          ]
        },
        {
          text: 'クラブ',
          key: 'club',
          children: [
            {
              text: 'クラブ設定',
              to: '/club',
              active: false,
            },
            // request resources management
            // {
            //   text: '施設利用',
            //   to: '/request-resource',
            //   active: false,
            // },
          ]
        },
      ]
    }

    let items = []
    if (roles.includes('PTA')) {
      items.push(
        {
          text: 'PTA',
          key: 'PTA',
          children: [
            {
              text: 'メール配信',
              to: '/send-email',
              active: false,
            },
            {
              text: '配信履歴',
              to: '/email-history',
              active: false,
            },
            {
              text: 'テンプレート設定',
              to: '/setting/email-template',
              active: false,
            }
          ]
        },
      )
    }

    if (roles.includes('club')) {
      items.push(
        {
          text: 'クラブ',
          key: 'club',
          children: [
            {
              text: 'クラブ設定',
              to: '/club',
              active: false,
            },
            // request resources management
            // {
            //   text: '施設利用',
            //   to: '/request-resource',
            //   active: false,
            // },
          ]
        },
      )
    }

    return items
  })

  const current = ref({
    parents: [],
    childs: [],
  })

  const setActiveParent = (key) => {
    current.value.parents.push(key)
  }

  const setActiveChild = (key) => {
    current.value.childs.push(key)
  }

  const setActive = (path) => {
    initCurrent()
    const parent = items.value.find(
      item => 
        item.children && 
        item.children.find(child => path.includes(child.to))
    )
    if (parent) {
      current.value.parents.push(parent.key)

      const child = parent.children.find(child => path.includes(child.to))
      if (child) {
        current.value.childs.push(child.to)
      }
    }
  }

  const initCurrent = () => {
    current.value.parents = []
    current.value.childs = []
  }

  const toogleParent = (key) => {
    const index = current.value.parents.indexOf(key)
    if (index === -1) {
      current.value.parents.push(key)
    } else {
      current.value.parents.splice(index, 1)
    }
  }

  return {
    items,
    current,
    setActiveParent,
    setActiveChild,
    setActive,
    toogleParent,
  }
});

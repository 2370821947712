<script setup>
import { ref, onMounted, getCurrentInstance } from 'vue'
const $root = getCurrentInstance()?.appContext.config.globalProperties

import { ArrowUturnLeftIcon } from '@heroicons/vue/24/outline';

import { useRouter } from "vue-router";
const router = useRouter();

import tinycolor from 'tinycolor2';

import RangeTimeVue from './widget/range-time.vue'

import { useEntranceStore } from 'store/entrance';
const entranceStore = useEntranceStore()

import { useEventTicket } from 'store/event-ticket';
const eventTicketStore = useEventTicket()

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import dayjs from 'dayjs'

import DateTimeHelper from 'helper/DatetimeHelper';

const rangeTime = ref({
    min: 0,
    max: 23,
    minValue: 0,
    maxValue: 23,
    minCurrent: 0,
    maxCurrent: 23
})
const maxMinSelect = ref({})

onMounted(async () => {
    $root.overlay.loading = true
    if (eventTicketStore.groups.length == 0) {
        eventTicketStore.filterGroup.orderBy = 'start_time'
        eventTicketStore.filterGroup.sort = 'ASC'
        await eventTicketStore.fetchGroups()
    }
    // get session filter
    const filter = localStorage.getItem('reportFilter')
    if (filter) {
        entranceStore.reportFilter = JSON.parse(filter)
    }
    await callFilterDate()

    await fetchData()
})

const selectEvent = ref({})
const callFilterDate = async () => {
    entranceStore.reportFilter.date = []
    if (eventTicketStore.groups.length == 0) return

    if (!entranceStore.reportFilter.group) {
        entranceStore.reportFilter.group = eventTicketStore.groups[0].id
    }

    const group = eventTicketStore.groups.filter(group => group.id == entranceStore.reportFilter.group)[0]
    const startDate = dayjs(group.start_time).format('YYYY-MM-DD');
    const endDate = (group.end_time) ? dayjs(group.end_time).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD');
    selectEvent.value = group

    maxMinSelect.value.min = startDate
    maxMinSelect.value.max = endDate

    entranceStore.reportFilter.date[0] = startDate
    entranceStore.reportFilter.date[1] = endDate
}

const days = ref([])
const callDays = async () => {
    let from = dayjs(entranceStore.reportFilter.date[0])
    const to = dayjs(entranceStore.reportFilter.date[1])

    const daysArray = [];
    while (from.isBefore(to) || from.isSame(to)) {
        daysArray.push(from.format('YYYY-MM-DD'));
        from = from.add(1, 'day');
    }
    days.value = daysArray
}

const fetchData = async () => {
    localStorage.setItem('reportFilter', JSON.stringify(entranceStore.reportFilter))
    await callDays()

    $root.overlay.loading = true
    await entranceStore.fetchReportDatas()
    $root.overlay.loading = false
}

const doSearch = async () => {
    await callFilterDate()
    fetchData()
}

const handleDate = (val) => {
    entranceStore.reportFilter.date[0] = dayjs(val[0]).format('YYYY-MM-DD')
    entranceStore.reportFilter.date[1] = dayjs(val[1]).format('YYYY-MM-DD')
    fetchData()
}

const format = (date) => {
    let arr = []
    arr[0] = dayjs(date[0]).format('YYYY年MM月DD日');
    arr[1] = dayjs(date[1]).format('YYYY年MM月DD日');
    return arr[0] + ',' + arr[1]
}
const calcWorkingHour = (date, hour) => {
    if (entranceStore.reportItems.datas[date] != undefined && entranceStore.reportItems.datas[date][hour] != undefined) {
        return entranceStore.reportItems.datas[date][hour]
    }
    return 0
}
const dragRangeTimeHander = (e) => {
    rangeTime.value[e.type] = parseInt(e.value)
}
const dragRangeTimeCancelHander = () => {
    rangeTime.value.minValue = rangeTime.value.minCurrent
    rangeTime.value.maxValue = rangeTime.value.maxCurrent
}
const dragRangeTimeSaveHander = () => {
    rangeTime.value.minCurrent = rangeTime.value.minValue
    rangeTime.value.maxCurrent = rangeTime.value.maxValue
}
const getColor = (value) => {
    const lightColor = '#FFFFFF' // màu nhạt nhất
    const darkColor = '#e6042d'  //màu đậm nhất
    const maxValue = entranceStore.reportItems.max > 50 ? entranceStore.reportItems.max : 50
    const percentage = value / maxValue;
    return tinycolor.mix(lightColor, darkColor, percentage * 100).toString(); // Kết hợp màu
}
const calPercent = () => {
    const members = entranceStore.reportItems.members
    const totalCheckin = entranceStore.reportItems.totalCheckin
    return ((totalCheckin / members) * 100).toFixed(1) + '%'
}
</script>
<template>
    <div class="w-full bg-white rounded-md p-4 flex flex-col gap-2">
        <div class="w-full">
            <button @click="router.push({ path: `/entrance` })" type="button"
                class="flex flex-row p-1 items-center group justify-center rounded rounded-mb bg-background hover:opacity-80 min-w-20 border text-sblue gap-1">
                <ArrowUturnLeftIcon
                    class="w-4 h-4 transition duration-300 delay-75 ease-in-out group-hover:scale-110" />
                戻る
            </button>
        </div>
        <!-- Start block content -->
        <div class="flex flex-col gap-2 w-full py-2">
            <!-- Start block search -->
            <div class="flex gap-2 justify-between">
                <div class="flex flex-row gap-1 items-center">
                    <template v-if="entranceStore.reportFilter.type == 'event'">
                        <select v-model="entranceStore.reportFilter.group"
                            class="px-4 h-8 border border-gray-300 rounded" @change="doSearch()">
                            <template v-for="group in eventTicketStore.groups">
                                <option :value="group.id">{{ group.name }}</option>
                            </template>
                        </select>
                    </template>
                </div>
            </div>
            <div class="flex flex-col gap-2 ">
                <div>
                    イベント名: <strong>{{ selectEvent.name }}</strong>
                </div>
                <div>
                    開始日: <strong>{{ dayjs(selectEvent.start_time).format("YYYY年MM月DD日　HH:mm") }}</strong>
                </div>
                <div>
                    終了日: <strong>{{ selectEvent.end_time ? dayjs(selectEvent.end_time).format("YYYY年MM月DD日　HH:mm") : ''
                        }}</strong>
                </div>
                <div>
                    グループ登録合計数: <strong>{{ entranceStore.reportItems.members }}</strong> <br />
                    入校者数: <strong>{{ entranceStore.reportItems.totalCheckin }}</strong> <br />
                    グループチェックイン率: <strong>{{ calPercent() }}</strong><br />
                    出校時チェックアウト忘れ人数: <strong>{{ entranceStore.reportItems.lostCheckout }}</strong>
                </div>
            </div>
        </div>
        <div class="flex gap-2 w-full">
            <div class="min-w-[320px]">
                <VueDatePicker v-model="entranceStore.reportFilter.date" range @update:model-value="handleDate"
                    :clearable="false" :format="format" :max-date="maxMinSelect.max" :min-date="maxMinSelect.min"
                    :enable-time-picker="false" locale="ja" auto-apply input-class-name="h-8" />
            </div>
            <div>
                <RangeTimeVue :range="rangeTime" @drag="dragRangeTimeHander" @drag:cancel="dragRangeTimeCancelHander"
                    @drag:save="dragRangeTimeSaveHander"
                    class="flex items-center gap-1 rounded border border-slate-400 bg-orange-200 px-4 py-1 font-normal text-slate-950 shadow-none transition-all delay-75 duration-300 hover:opacity-80">
                    <template #text>時間帯絞込</template>
                    <template #label>時間帯絞込</template>
                </RangeTimeVue>
            </div>
        </div>
        <div class="flex gap-2 w-full">
            <table class="table border-separate border-spacing-0 border-b-slate-800 border-r" ref="refTable">
                <thead ref="refHead" class="text-slate-900 bg-white shadown">
                    <tr>
                        <th
                            class="text-center font-normal leading-none transition-all delay-75 duration-300 ease-in-out min-w-10 w-10 2xl:w-12 2xl:min-w-12 h-6">
                        </th>
                        <template v-for="h in 24">
                            <th v-if="h - 1 >= rangeTime.minCurrent && h - 1 <= rangeTime.maxCurrent"
                                class="bg-orange-200 border-b border-b-slate-800 border-l border-t border-l-slate-300 border-t-slate-300 text-center font-normal leading-none transition-all delay-75 duration-300 ease-in-out min-w-10 w-10 2xl:w-12 2xl:min-w-12">
                                <div class="flex items-center justify-center">{{ h - 1 }}</div>
                            </th>
                        </template>
                        <th
                            class="bg-orange-200 border-b border-b-slate-800 border-l border-t border-l-slate-300 border-t-slate-300 text-center font-normal leading-none transition-all delay-75 duration-300 ease-in-out min-w-10 w-10 2xl:w-12 2xl:min-w-12">
                            合計
                        </th>
                    </tr>
                </thead>
                <tbody v-if="!$root.overlay.loading">
                    <tr v-for="(d, dindex) in days">
                        <td class="border-l border-t border-slate-300 text-slate-900" :class="{
                'border-b': dindex + 1 == days.length,
            }">
                            <div class="flex items-center justify-center">
                                {{ DateTimeHelper.getDayOfWeek(entranceStore.reportFilter.date, d) }}
                            </div>
                        </td>
                        <template v-for="h in 24">
                            <td v-if="h - 1 >= rangeTime.minCurrent && h - 1 <= rangeTime.maxCurrent"
                                :style="{ background: getColor(calcWorkingHour(d, h - 1)) }"
                                class="border-l border-t border-slate-300 text-center text-slate-900 transition-all delay-75 duration-300 ease-in-out"
                                :class="{
                'border-b': dindex + 1 == days.length,
            }">
                                {{ calcWorkingHour(d, h - 1) }}
                            </td>
                        </template>
                        <td class="border-l border-t border-slate-300 text-center text-slate-900 transition-all delay-75 duration-300 ease-in-out font-bold"
                            :class="{
                'border-b': dindex + 1 == days.length,
            }">
                            {{ entranceStore.reportItems.totals[d] ?? 0 }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script setup>
import { ref } from 'vue'
import { FunnelIcon } from '@heroicons/vue/24/outline'
import { useClubScheduleStore } from '../../../js/store/club-schedule'

const emit = defineEmits(['filter'])
const clubScheduleStore = useClubScheduleStore()

const props = defineProps({
  filter: {
    type: Object,
    default: () => ({
      rooms: [],
      clubs: [],
    }),
    required: true,
  },
})

const filter = ref({
  rooms: props.filter.rooms,
  clubs: props.filter.clubs,
})

const setFilter = () => {
  clubScheduleStore.pagination.rooms = filter.value.rooms
  clubScheduleStore.pagination.clubs = filter.value.clubs
  clubScheduleStore.setFilter(false)
  emit('filter')
}

const closeFilter = () => {
  filter.value.rooms = clubScheduleStore.pagination.rooms
  filter.value.clubs = clubScheduleStore.pagination.clubs
  clubScheduleStore.setFilter(false)
}
</script>
<template>
  <div v-show="clubScheduleStore.showFilter"
    class="min-w-40 max-w-80 flex-none transition-all duration-300 delay-75 ease-in-out absolute top-14 left-3 z-10 bg-white/80 shadow-2xl backdrop-blur backdrop-filter"
  >
    <div class="flex flex-col gap-4 w-full">
      <div class="flex flex-col gap-2">
        <div class="bg-gray-100 border-t-2 border-t-primary flex items-center gap-1 font-semibold p-1">
          <FunnelIcon class="w-4 h-4" />
          申請施設
        </div>
        <div class="flex flex-col gap-1 p-1">
          <label v-for="r in clubScheduleStore.rooms" class="flex items-center gap-1 px-1" :title="r.name">
            <input type="checkbox" class="w-4 h-4" :value="r.id" v-model="filter.rooms" />
            <span class="truncate">{{ r.name }}</span>
          </label>
        </div>
      </div>
      <div class="grow">
        <div class="flex flex-col gap-2">
          <div class="bg-gray-100 border-t-2 border-t-primary flex items-center gap-1 font-semibold p-1">
            <FunnelIcon class="w-4 h-4" />
            申請クラブ
          </div>
          <div class="grow">
            <div class="overflow-y-auto flex flex-col gap-1 p-1 max-w-80">
              <label v-for="r in clubScheduleStore.clubs" class="flex items-center gap-1 px-1" :title="r.name">
                <input type="checkbox" class="w-4 h-4" :value="r.id" v-model="filter.clubs" />
                <span class="truncate">{{ r.name }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="flex items-center gap-1 font-semibold justify-center px-2 pb-2">
        <button @click="closeFilter" 
          class="border px-2 py-0.5 rounded w-1/2 hover:opacity-80">閉じる</button>
        <button @click="setFilter" 
          class="bg-primary px-2 py-0.5 rounded text-white w-1/2 hover:opacity-80">適用</button>
      </div>
    </div>
  </div>
</template>
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import 'dayjs/locale/en'
import 'dayjs/locale/ja'
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(timezone)
dayjs.extend(customParseFormat);

// set timezone to Ho Chi Minh
dayjs.tz.setDefault(import.meta.env.VITE_APP_TIMEZONE)

// set locale to ja
dayjs.locale('ja')

const DateTimeHelper = {
  getDaysOfMonth(year, month) {
    return dayjs(`${year}-${month}-01`).daysInMonth()
  },
  generateCalendars(year, monthStart, monthRange) {
    const calendars = []
    for (let i = 0; i < monthRange; i++) {
      let m = monthStart + i
      if (monthStart < 3 && m > 3) {
        break
      }

      if (monthStart > 3 && m - 12 > 3) {
        break
      }
      let y = year
      const days = this.getDaysOfMonth(y, m)
      calendars.push({
        year:  m <= 12 ? y : y + 1,
        month: m <= 12 ? m : (m -12),
        days,
      })
    }

    // get max days of month
    const maxDays = Math.max(...calendars.map(calendar => calendar.days))
    // add blank days to calendars
    calendars.forEach(calendar => {
      calendar.blankDays = maxDays - calendar.days
    })

    return calendars
  },
  getWeekday(year, month, day) {
    return dayjs(`${year}-${month}-${day}`).locale('ja').format('ddd')
  },
  isSat(year, month, day) {
    // check if date is Saturday
    return this.getWeekday(year, month, day) === '土'
  },
  isSun(year, month, day) {
    // check if date is Sunday
    return this.getWeekday(year, month, day) === '日'
  },
  isWeekend(year, month, day) {
    // check if date is weekend
    return this.isSat(year, month, day) || this.isSun(year, month, day)
  },
  isVietnamHoliday(year, month, day) {
    return false
  },

  getBeforeMonth(year, month) {
    if (month === 1) {
      return {
        year: year - 1,
        month: 12,
      }
    }

    return {
      year,
      month: month - 1,
    }
  },
  getAfterMonth(year, month) {
    if (month === 12) {
      return {
        year: year + 1,
        month: 1,
      }
    }

    return {
      year,
      month: month + 1,
    }
  },
  // on month view mode only
  generateMonthCalendar(year, month) {
    // generate days of month group by week starting from Sunday to Saturday
    // get before month days and after month days if needed
    let days = []
    const daysOfMonth = this.getDaysOfMonth(year, month)
    const firstDay = dayjs(`${year}-${month}-01`).day()
    const lastDay = dayjs(`${year}-${month}-${daysOfMonth}`).day()

    // get before month days
    let beforeMonthDays = firstDay
    if (beforeMonthDays === 0) {
      beforeMonthDays = 7
    }

    const before = this.getBeforeMonth(year, month)
    for (let i = 0; i < beforeMonthDays; i++) {
      days.unshift({
        year: before.year,
        month: before.month,
        day: this.getDaysOfMonth(year, month - 1) - i,
        isBeforeMonth: true,
        isSat: this.isSat(before.year, before.month, this.getDaysOfMonth(year, month - 1) - i),
        isSun: this.isSun(before.year, before.month, this.getDaysOfMonth(year, month - 1) - i),
      })
    }

    // get days of month
    for (let i = 1; i <= daysOfMonth; i++) {
      days.push({
        year,
        month,
        day: i,
        isSat: this.isSat(year, month, i),
        isSun: this.isSun(year, month, i),
      })
    }

    // get after month days
    let afterMonthDays = 6 - lastDay
    if (lastDay === 0) {
      afterMonthDays = 0
    }

    const after = this.getAfterMonth(year, month)
    for (let i = 1; i <= afterMonthDays; i++) {
      days.push({
        year: after.year,
        month: after.month,
        day: i,
        isAfterMonth: true,
        isSat: this.isSat(after.year, after.month, i),
        isSun: this.isSun(after.year, after.month, i),
      })
    }

    // group days by week
    const weeks = []
    for (let i = 0; i < days.length; i += 7) {
      weeks.push(days.slice(i, i + 7))
    }

    return weeks
  },
  // on day view mode only
  // show 24 hours of a day
  generateDayCalendar(year, month, day) {
    const hours = []
    for (let i = 0; i < 24; i++) {
      hours.push({
        hour: i,
      })
    }

    return hours
  },
  generateWeekHeader(year, month, day) {
    const headers = []

    // generate like [11/7(日), ... , 11/13(土)]
    const startOfWeek = dayjs(`${year}-${month}-${day}`).startOf('week')
    year = startOfWeek.year()
    month = startOfWeek.month() + 1 // dayjs months are 0-indexed
    day = startOfWeek.date()

    for (let i = 0; i < 7; i++) {
      const currentDay = startOfWeek.add(i, 'day')
      headers.push({
        year: currentDay.year(),
        month: currentDay.month() + 1,
        day: currentDay.date(),
        weekday: `${currentDay.month() + 1}月${currentDay.date()}日(${this.getWeekday(currentDay.year(), currentDay.month() + 1, currentDay.date())})`,
        isSun: this.isSun(currentDay.year(), currentDay.month() + 1, currentDay.date()),
        isSat: this.isSat(currentDay.year(), currentDay.month() + 1, currentDay.date()),
      })
    }

    return headers
  },
  // on week view mode only
  // show 7 days of a week starting from Sunday to Saturday and 24 hours of each day group by hour
  generateWeekCalendar(year, month, day) {
    const weeks = []

    // Adjust to make Sunday the first day of the week
    const startOfWeek = dayjs(`${year}-${month}-${day}`).startOf('week')
    year = startOfWeek.year()
    month = startOfWeek.month() + 1 // dayjs months are 0-indexed
    day = startOfWeek.date()

    // Generate the days of the week
    const days = []
    for (let i = 0; i < 7; i++) {
      const currentDay = startOfWeek.add(i, 'day')
      days.push({
        year: currentDay.year(),
        month: currentDay.month() + 1,
        day: currentDay.date(),
      })
    }

    // Generate the hours for each day
    for (let h = 0; h < 24; h++) {
      const hourBlock = {
        hour: h,
        days: [],
      }
      for (const dayInfo of days) {
        hourBlock.days.push({
          ...dayInfo,
          hour: h,
          isSat: this.isSat(dayInfo.year, dayInfo.month, dayInfo.day),
          isSun: this.isSun(dayInfo.year, dayInfo.month, dayInfo.day),
        })
      }
      weeks.push(hourBlock)
    }

    return weeks
  },

  // format date to string like '2024年11月7日'
  formatDate(year, month, day) {
    return `${year}年${month}月${day}日`
  },
  convertToDate(year, month, day) {
    return dayjs(`${year}-${month}-${day}`).toDate()
  },
  getWeekRange(year, month, day) {
    // return like 2024年11月4日 – 10日
    
    // Adjust to make Sunday the first day of the week
    const startOfWeek = dayjs(`${year}-${month}-${day}`).startOf('week')
    year = startOfWeek.year()
    month = startOfWeek.month() + 1 // dayjs months are 0-indexed
    day = startOfWeek.date()

    const endOfWeek = startOfWeek.add(6, 'day')
    return `${year}年${month}月${day}日 – ${endOfWeek.date()}日`
  },
  getCurrentDate() {
    const now = dayjs()
    return {
      year: now.year(),
      month: now.month() + 1,
      day: now.date(),
    }
  },
  addMonth(year, month, v) {
    const ym = dayjs(`${year}-${month}-01`).add(v, 'month')

    return {
      year: ym.year(),
      month: ym.month() + 1,
    }
  },
  addWeek(year, month, day, v) {
    const ymd = dayjs(`${year}-${month}-${day}`).add(v, 'week')

    return {
      year: ymd.year(),
      month: ymd.month() + 1,
      day: ymd.date(),
    }
  },
  addDay(year, month, day, v) {
    const ymd = dayjs(`${year}-${month}-${day}`).add(v, 'day')

    return {
      year: ymd.year(),
      month: ymd.month() + 1,
      day: ymd.date(),
    }
  }
}

export default DateTimeHelper

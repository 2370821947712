import { defineStore } from 'pinia'

export const useFronEndClubStore = defineStore('club', {
  state: () => ({
    club: {},
    filter: { phone: '', related: 0 },
    info: {},

    member: {},
    intMember: { id: 0, name: '', romaji_name: '', relationship: 'other', dob: '', gender: 'male', email: '', phone: '', phone_check: false, phone_exist: false, kyc: '', note: '', related_to_school: false, child_enrolled: false },
    finded: 0,
    confirm: false,
    availRelationship: [],
  }),
  getters: {
  },
  actions: {
    async getItem(id) {
      if (!id) {
        return
      }
      const response = await axios.get(`/api/front-end/club/${id}`)
      if (response.status === 200) {
        this.club = response.data
      }
      else {
        this.club = {}
      }
    },

    async search() {
      if (!this.filter.phone || !this.club.id) {
        return
      }
      this.availRelationship = []
      this.filter.related = this.filter.related == 1 ? true : false
      this.filter.club_id = this.club.id;
      const response = await axios.post(`/api/front-end/club-member`, this.filter)
      if (response.status === 200) {
        this.info = response.data
      }
      if (this.info.member && this.info.member.type) {
        this.filter.related = this.info.member.related_to_school
        this.availRelationship.push(this.info.member.relationship)
      }

      if (this.info.related && this.info.related.length > 0 && this.info.related_join.length > 0) {
        this.info.related = this.info.related.map((rela) => {
          this.availRelationship.push(rela.relationship)
          rela.join = (this.info.related_join.filter(i => i.type == rela.type && i.id == rela.id).length > 0) ? true : false
          return rela
        })
      }

      this.finded = this.info.member ? 1 : 0

    },

    async joinClubExist($datas) {
      if (!this.club.id) {
        return
      }
      const datas = { 'club_id': this.club.id, 'data': $datas, 'guest': !this.filter.related }
      const response = await axios.post(`/api/front-end/join-club/exist`, datas)

      return (response.status === 200 && response.data.success) ? true : false
    },
    async register($datas) {
      if (!this.club.id) {
        return
      }
      $datas.related_to_school = this.filter.related
      const datas = { 'club_id': this.club.id, 'data': $datas }
      const response = await axios.post(`/api/front-end/join-club/register`, datas)
      if (response.status === 200 && response.data.success) {
        if ($datas.id > 0 || this.finded == 0) {
          this.search()
        }
        return true
      }
      else {
        return false
      }
    },
    async validatePhone(phone) {
      if (!phone || !this.club.id) {
        return false
      }
      let phoneExist = false
      if (['mother', 'father'].includes(this.member.relationship)) {
        phoneExist = this.member.phone_exist
      }
      const member_id = (this.info.member && this.info.member.id) ? this.info.member.id : 0
      const type = (this.info.member && this.info.member.type) ? this.info.member.type : ''
      const datas = { 'exist': phoneExist, 'phone': phone, 'club_id': this.club.id, 'member_id': member_id, 'type': type }
      const response = await axios.post(`/api/front-end/validate-phone`, datas)
      return (response.status === 200 && response.data.exist == true) ? true : false;
    }

  },
});

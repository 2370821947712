<script setup>
import { ref, getCurrentInstance, onMounted, watch } from 'vue'
import { useRoute } from 'vue-router';
import { useClubStore } from '../../../../js/store/club';
import DateTimeHelper from '../../../../js/helper/DatetimeHelper.js';
import Apply from './Apply.vue';
import Table from './Table.vue';

const $root = getCurrentInstance()?.appContext.config.globalProperties
const route = useRoute()
const clubId = parseInt(route.params.id);

const clubStore = useClubStore()

// from 4 to 12 and 1 to 3
const months = ref([
  ...Array.from({ length: 9 }, (_, i) => 4 + i),
  ...Array.from({ length: 3 }, (_, i) => 1 + i),
])

const currentDate = DateTimeHelper.getCurrentDate()
const currentYear = parseInt(DateTimeHelper.format(currentDate, 'YYYY'))
const currentMonth = parseInt(DateTimeHelper.format(currentDate, 'MM'))

const pagination = ref({
  schoolYear: currentMonth < 4 ? currentYear - 1 : currentYear,
  year: currentYear,
  month: currentMonth,
})
const items = ref([])

const schoolYears = ref([
  currentMonth < 4 ? currentYear - 1 : currentYear,
  currentMonth < 4 ? currentYear : currentYear + 1,
])

const changeMonth = (month) => {
  pagination.value.month = month
  pagination.value.year = month < 4  
    ? pagination.value.schoolYear + 1 
    : pagination.value.schoolYear

  getItems()
}

onMounted(() => {
  getFirstSchoolYear()
  getItems()
})

const getFirstSchoolYear = () => {
  clubStore.getFirstSchoolYear(clubId).then((res) => {
    if (res.data.data) {
      pagination.value.schoolYear = parseInt(res.data.data)

      schoolYears.value = [
        pagination.value.schoolYear,
        pagination.value.schoolYear + 1,
      ]
    }
  })
}

const getItems = () => {
  $root.overlay.loading = true
  clubStore.getSchedules(clubId, {
    year: pagination.value.year,
    month: pagination.value.month,
  }).then((res) => {
    items.value = res.data.data
    $root.overlay.loading = false
  })
}

watch(() => pagination.value.schoolYear, () => {
  pagination.value.year = pagination.value.month < 4  
    ? pagination.value.schoolYear + 1 
    : pagination.value.schoolYear

  getItems()
})
</script>
<template>
  <div class="flex flex-col items-center justify-center gap-4">
    <div class="flex flex-col max-w-2xl gap-4">
      <div class="flex items-end gap-4 transition-all delay-75 duration-300 ease-in-out">
        <div class="font-semibold text-lg">
          {{ pagination.year }}
        </div>
        <div class="flex items-end gap-px transition-all delay-75 duration-300 ease-in-out">
          <button 
            v-for="m in months" 
            class="flex p-2 rounded-full items-center justify-center transition-all delay-75 duration-300 ease-in-out"
            :class="{
              'bg-graysp w-8 h-8': m != pagination.month,
              'bg-gray-200 w-10 h-10 text-lg': m === pagination.month
            }"
            @click.prevent="changeMonth(m)"
          >{{ m }}</button>
        </div>
        <div>
          <select class="border rounded border-gray-200" v-model="pagination.schoolYear">
            <option v-for="y in schoolYears" :value="y">{{ y }}年度</option>
          </select>
        </div>
      </div>
    </div>
    <Apply :pagination="pagination" :items="items" @refresh="getItems" />
    <Table v-if="items && items.length > 0" :items="items" @refresh="getItems" />
  </div>
</template>
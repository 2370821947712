<script setup>
import { ref, onMounted, getCurrentInstance } from 'vue';
import { useRoute } from 'vue-router';
import { MinusCircleIcon } from '@heroicons/vue/24/solid';
import AddResourceModal from './AddResourceModal.vue';
import { useClubRequestStore } from '../../../../js/store/club-request';
import { useClubStore } from '../../../../js/store/club';

const $root = getCurrentInstance()?.appContext.config.globalProperties;
const clubRequestStore = useClubRequestStore();
const clubStore = useClubStore();
const errors = ref({});

// get id from route
const route = useRoute();
const clubId = parseInt(route.params.id);

const remove = (id) => {
  const c = confirm('削除しますか？');
  if (!c) {
    return
  }

  // 削除処理
  clubStore.item.resources = clubStore.item.resources.filter(r => r.resource_id !== id);
};

const save = async () => {
  errors.value = {};

  // 保存処理
  $root.overlay.loading = true;
  const response = await clubStore.saveResources();
  if (response.status !== 200) {
    $root.push.error('物品の保存に失敗しました');
    $root.overlay.loading = false;
    return;
  }

  // エラー処理
  errors.value = response.data.errors.reduce((acc, cur) => {
    acc[cur.resource_id] = cur.message;
    return acc;
  }, {});

  $root.overlay.loading = false;

  // エラーメッセージ表示
  if (Object.keys(errors.value).length) {
    Object.keys(errors.value).forEach(key => {
      $root.push.error(errors.value[key]);
    });
  } else {
    $root.push.success('物品を保存しました');
  }

  clubRequestStore.getResources(clubId);
};

onMounted(async () => {
  errors.value = {};

  clubRequestStore.getResources(clubId);
});

const getResourceName = (id) => {
  const resource = clubRequestStore.resources.find(r => r.id === id);
  return resource ? resource.name : '';
};
const getResourceQty = (id) => {
  const resource = clubRequestStore.resources.find(r => r.id === id);
  return resource ? resource.qty : 0;
};
const getResourceUnit = (id) => {
  const resource = clubRequestStore.resources.find(r => r.id === id);
  return resource ? resource.unit : '';
};
const getResourceTotalQty = (id) => {
  const resource = clubRequestStore.resources.find(r => r.id === id);
  return resource ? resource.quantity : 0;
};
</script>
<template>
  <div class="flex w-full justify-center">
    <div class="flex flex-col gap-2 max-w-xl">
      <div class="flex justify-between gap-2 items-end">
        <div class="flex flex-col text-error text-xs">
          ※破損・紛失等の場合は必ず報告を行ってください。
          <div>※弁償等については別途、報告時に面談致します。</div>
        </div>
        <div>
          <AddResourceModal />
        </div>
      </div>
      <div class="table">
        <table class="w-full border-b border-gray-200">
          <thead class="bg-graysp border-t-2 border-t-primary border-b border-b-gray-200">
            <tr>
              <th class="text-left p-1 font-normal">物品名</th>
              <th class="p-1 text-left font-normal">借入数／学校保有数</th>
              <th class="text-end p-1"></th>
            </tr>
          </thead>
          <tbody class="divide-y">
            <template v-if="clubStore.item.resources && clubStore.item.resources.length">
              <tr v-for="r in clubStore.item.resources">
                <td class="px-1 text-left">
                  {{ getResourceName(r.resource_id) }}
                </td>
                <td class="px-1">
                  <div class="flex items-center gap-1">
                    <input 
                      type="number" 
                      class="w-16 text-end border border-gray-200 rounded px-2 py-1" 
                      v-model="r.quantity" 
                      min="1" 
                      step="1" 
                      :class="{ 'bg-error/10': errors[r.resource_id] }"
                    />
                    <div class="flex items-center">
                      <!-- <span class="text-green text-xs font-semibold" title="在庫あり">
                        {{ getResourceQty(r.resource_id) }}
                      </span> -->
                      <span class="text-gray-400 text-xs">／</span>
                      <span class="text-charcoal text-xs font-semibold" title="合計">
                        {{ getResourceTotalQty(r.resource_id) }}
                      </span>
                      <span class="text-gray-400 text-xs">
                        {{ getResourceUnit(r.resource_id) }}
                      </span>
                    </div>
                  </div>
                </td>
                <td class="px-1 text-end">
                  <div class="flex justify-end items-center">
                    <button class="group hover:opacity-80" @click.prevent="remove(r.resource_id)" title="削除">
                      <MinusCircleIcon class="w-8 h-8 fill-error" />
                    </button>
                  </div>
                </td>
              </tr>
            </template>
            <tr v-else>
              <td class="p-1 text-center bg-yellow-500/10 text-gray-400" colspan="3">
                物品がありません
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="flex justify-center">
        <button class="px-4 py-1 bg-primary font-semibold text-white rounded min-w-36" @click.prevent="save">
          保存
        </button>
      </div>
      <!-- <div v-if="clubStore.item.resources && clubStore.item.resources.length" class="flex items-center justify-center gap-4 text-xs mt-4 text-gray-400"> -->
        <!-- avaiable -->
        <!-- <div class="flex items-center gap-1">
          <span class="w-3 h-3 rounded-sm bg-green"></span>
          <span>在庫あり</span>
        </div> -->
        <!-- total orginal -->
        <!-- <div class="flex items-center gap-1">
          <span class="w-3 h-3 rounded-sm bg-charcoal"></span>
          <span>合計</span>
        </div> -->
      <!-- </div> -->
    </div>
  </div>
</template>

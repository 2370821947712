<script setup>
import { ref, getCurrentInstance } from 'vue'
import { useRoute } from 'vue-router';
import { TransitionRoot, TransitionChild, Dialog, DialogPanel } from '@headlessui/vue'
import { useClubStore } from '../../../../js/store/club'
import DateTimeHelper from '../../../../js/helper/DatetimeHelper.js'

const $root = getCurrentInstance()?.appContext.config.globalProperties
const route = useRoute()
const clubStore = useClubStore()
const clubId = parseInt(route.params.id);

const emit = defineEmits(['refresh'])

const isOpen = ref(false)
const item = ref({})

const open = (obj) => {
  item.value = obj
  isOpen.value = true
}
const close = () => {
  isOpen.value = false
}

const ok = () => {
  $root.overlay.loading = true
  clubStore.cancelSchedule(clubId, item.value.id).then(() => {
    $root.push.success('施設利用日を取り消しました')
    emit('refresh')
    close()
  }).catch(() => {
    $root.push.error('施設利用日の取り消しに失敗しました')
  }).finally(() => {
    $root.overlay.loading = false
  })
}

defineExpose({ open, close })
</script>
<template>
  <!-- この施設利用日を取り消ししますか？ -->
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-10">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-fit transform bg-white text-left align-middle shadow-xl transition-all border-t-2 border-t-error"
            >
              <div class="w-full flex flex-col gap-6 items-center justify-center px-8 py-4">
                <div class="flex items-center justify-between w-full bg-purple-200 px-2 py-1">
                  <div class="flex w-10 items-center justify-between">
                    <div>{{ DateTimeHelper.format(item?.start, 'D')  }}</div>
                    <div>{{ DateTimeHelper.getDayOfWeekShort(item?.start) }}</div>
                  </div>
                  <div class="flex justify-center">
                    {{ item.room_name }}
                  </div>
                  <div class="flex flex-col items-center">
                    {{ DateTimeHelper.format(item.start, 'HH:mm') }} ～ {{ DateTimeHelper.format(item.end, 'HH:mm') }}
                  </div>
                </div>

                <div class="text-lg font-semibold text-error">
                  この施設利用日を取り消ししますか？
                </div>
                <div class="flex flex-col gap-2 items-center">
                  <div>※取消後は施設を利用できません。</div>
                  <div>取消後の利用日の再利用依頼は一切受付けできません。</div>
                </div>
                <div class="flex items-center justify-between gap-4 font-semibold mt-4">
                  <button class="text-white bg-error rounded px-4 py-1 hover:opacity-80" @click.prevent="ok">
                    同意して取り消し
                  </button>
                  <button class="border border-gray-500 rounded px-4 min-w-1/2 py-1 hover:opacity-80" @click.prevent="close">
                    取消しないで閉じる
                  </button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { ref, getCurrentInstance } from 'vue'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { ArrowPathIcon, TrashIcon, QrCodeIcon, PencilIcon, ExclamationTriangleIcon, PhoneIcon } from '@heroicons/vue/24/outline';
import { useClubStore } from '../../../../js/store/club';
import { useMember } from '../../../../js/store/member';
import TablePagination from '../../../components/TablePagination.vue';
import DateTimeHelper from '../../../../js/helper/DatetimeHelper';

const $root = getCurrentInstance()?.appContext.config.globalProperties
const clubStore = useClubStore()
const memberStore = useMember()

const emit = defineEmits(['edit', 'remove'])

const edit = async (type, item) => {
  await memberStore.getItem(item.id, clubStore.item.id, item.type)
  emit('edit', item)
}

const remove = async (type, item) => {
  const c = confirm('本当に削除しますか？')
  if (!c) {
    return
  }

  $root.overlay.loading = true
  try {
    await clubStore.removeMember(item)
    $root.push.success('メンバーを削除しました')
  } catch (error) {
    $root.push.error('メンバーの削除に失敗しました')
  }
  $root.overlay.loading = false

  emit('remove', item)
}
const memberType = (member) => {
  if ((member.member_type === 'guest_related' || member.member_type === 'guest') && member.related_to_school) {
    if (member.relationship == 'staff') {
      return '教職員' +  (member.child_enrolled == true ? '(兼保護者)' : '')
    }
    else if (member.relationship == 'student') {
      return '生徒'
    }
    else if (['father', 'mother'].includes(member.relationship)) {
      return '保護者'
    }
    else if (member.relationship == 'relatives') {
      return '在籍生徒の親族'
    }
    else if (member.relationship == 'children') {
      return '未就学児'
    }
    return 'その他'
  }
  else if (member.member_type === 'guest_related' || member.member_type === 'guest') {
    return 'ゲスト'
  }
  else if (member.member_type === 'student') {
    return '学生'
  }
  return '保護者'
}
</script>
<template>
  <div class="flex flex-col gap-2">
    <table class="w-full table">
      <thead class="border-t-2 border-t-secondary border-b border-b-gray-200 bg-gray-100 text-xs">
        <tr>
          <th class="text-left p-1">名前</th>
          <th class="text-left p-1">電話番号</th>
          <th class="text-left p-1">タイプ</th>
          <th class="text-center p-1">QRコード</th>
          <th class="text-center p-1">QR利用(有効・無効)</th>
          <th class="text-right p-1"></th>
        </tr>
      </thead>
      <tbody>
        <template v-if="clubStore.item.members && clubStore.item.members.length">
          <tr v-for="member in clubStore.item.members" class="border-b border-b-gray-200">
            <td class="p-1">
              <span v-html="member.name"></span>
              <span v-if="member.is_kid">
                <span class="text-xs text-gray-400">（子供）</span>
              </span>
              <span v-if="member.related_to_school">
                <span class="text-xs text-gray-400">（学校関連）</span>
              </span>
            </td>
            <td class="p-1 group">
              <div v-if="member.phone"
                class="hover:underline hover:text-blue-500 transition-all delay-75 ease-in-out duration-300">
                <a :href="`tel:${member.phone}`" title="電話をかける">{{ member.phone }}</a>
              </div>
            </td>
            <td class="p-1">
              {{ memberType(member) }}
            </td>
            <td class="text-center p-1">
              <div class="flex items-center justify-center gap-1">
                <Popover v-if="member.qr_code_image" v-slot="{ open }" class="relative">
                  <PopoverButton title="QRコードを表示" class="cursor-pointer group">
                    <QrCodeIcon
                      class="w-5 h-5 p-px border rounded group-hover:scale-110 transition duration-300 delay-75 ease-in-out" />
                  </PopoverButton>
                  <transition enter-active-class="transition duration-200 ease-out"
                    enter-from-class="translate-y-1 opacity-0" enter-to-class="translate-y-0 opacity-100"
                    leave-active-class="transition duration-150 ease-in" leave-from-class="translate-y-0 opacity-100"
                    leave-to-class="translate-y-1 opacity-0">
                    <PopoverPanel v-slot="{ close }"
                      class="absolute z-10 transform px-4 -top-20 -left-1/2 -translate-x-1/3">
                      <div class="flex flex-col gap-2 w-[300px] p-2 rounded-lg bg-white shadow-2xl">
                        <img :src="member.qr_code_image" alt="QRコード" loading="lazy" />

                        <div class="flex items-center gap-2 justify-center">
                          <!-- download button -->
                          <a :href="member.qr_code_image" download="qr_code.png"
                            class="p-1 rounded hover:opacity-80 group transition duration-300 delay-75 ease-in-out bg-primary text-white">
                            ダウンロード
                          </a>

                          <!-- close button -->
                          <button
                            class="p-1 rounded hover:opacity-80 group transition duration-300 delay-75 ease-in-out border"
                            @click.prevent="close">
                            閉じる
                          </button>
                        </div>
                      </div>
                    </PopoverPanel>
                  </transition>
                </Popover>
                <div v-if="DateTimeHelper.isBeforeToday(member.expired_at)" title="有効期限が切れました"
                  class="cursor-pointer">
                  <ExclamationTriangleIcon class="2-4 h-4 text-error" />
                </div>
              </div>
            </td>
            <td class="p-1">
              <div class="flex justify-center" v-if="member.status">
                <svg class="w-5 h-5 fill-primary" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M19.4944 3.13333C19.716 3.00004 19.9806 2.95776 20.2327 3.01537C20.4848 3.07299 20.7047 3.22601 20.8464 3.44233L21.8364 4.95233C21.9655 5.14959 22.0207 5.3861 21.9923 5.62011C21.964 5.85413 21.8539 6.0706 21.6814 6.23133L21.6784 6.23533L21.6644 6.24833L21.6074 6.30133L21.3824 6.51633C20.1374 7.72392 18.9302 8.96986 17.7624 10.2523C15.5654 12.6683 12.9564 15.8303 11.2004 18.8983C10.7104 19.7543 9.51342 19.9383 8.80342 19.1993L2.31842 12.4613C2.22549 12.3647 2.15292 12.2504 2.10504 12.1252C2.05716 12 2.03493 11.8665 2.03969 11.7325C2.04445 11.5986 2.07609 11.4669 2.13274 11.3454C2.18938 11.2239 2.26987 11.1151 2.36942 11.0253L4.32942 9.25733C4.50167 9.10204 4.72253 9.01144 4.9542 9.00104C5.18588 8.99064 5.41396 9.06109 5.59942 9.20033L8.90843 11.6813C14.0774 6.58433 17.0084 4.62833 19.4944 3.13333Z" />
                </svg>
              </div>
              <div class="flex justify-center" v-else>
                <svg class="w-4 h-4 fill-gray-500" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M4.12708 3.14769L14.8532 13.8738C16.0712 12.4141 16.6992 10.5515 16.6136 8.65224C16.5281 6.75298 15.7352 4.95442 14.3908 3.61008C13.0465 2.26574 11.2479 1.47286 9.34868 1.3873C7.44943 1.30174 5.58685 1.92968 4.12708 3.14769ZM13.8748 14.8523L3.14862 4.12615C1.9306 5.58593 1.30266 7.4485 1.38822 9.34776C1.47378 11.247 2.26666 13.0456 3.611 14.3899C4.95534 15.7343 6.75391 16.5271 8.65316 16.6127C10.5524 16.6983 12.415 16.0703 13.8748 14.8523ZM2.63631 2.63631C6.15046 -0.878769 11.8486 -0.878769 15.3637 2.63631C18.8788 6.15138 18.8788 11.8486 15.3637 15.3637C11.8486 18.8788 6.15138 18.8788 2.63631 15.3637C-0.878769 11.8486 -0.878769 6.15138 2.63631 2.63631Z" />
                </svg>
              </div>
            </td>
            <td class="p-1">
              <div class="flex items-center justify-end gap-2 w-full">
                <button class="group cursor-pointer"
                  @click.prevent="edit('edit', { id: member.id, type: member.member_type })">
                  <PencilIcon
                    class="w-4 h-4 text-primary group-hover:scale-110 transition-all duration-300 delay-75 ease-in-out" />
                </button>
                <button class="group" @click.prevent="remove('remove', { id: member.id, type: member.member_type })">
                  <TrashIcon
                    class="w-4 h-4 text-error group-hover:scale-110 transition-all duration-300 delay-75 ease-in-out" />
                </button>
              </div>
            </td>
          </tr>
        </template>
        <tr v-else>
          <td colspan="5" class="text-center text-xxs text-gray-200 p-1">データがありません</td>
        </tr>
      </tbody>
    </table>

    <TablePagination v-model:currentPage="clubStore.currentPageMembers" :total="clubStore.totalMembers"
      :perPage="clubStore.perpageMembers" :onPageChange="clubStore.getMembers" :extend="true"
      :total-childs="clubStore.item.total_childs" :total-relateds="clubStore.item.total_relateds" />

    <!-- help for ExclamationTriangleIcon -->
    <div class="flex items-center">
      <ExclamationTriangleIcon class="w-4 h-4 text-error" />
      <span class="text-xs text-gray-400">有効期限が切れたメンバーはQRコードを表示できません</span>
    </div>
  </div>
</template>